<template>
  <div>
    <v-card>
      <v-card-title>
        {{
          currentUser.full_name
        }}
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="6"
            class="d-flex align-center pr-4"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiClipboardMinusOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-2">
              <p class="text-xs mb-0">
                {{ $t('Borrowing') }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ getUserOverview.borrowed }}
              </h3>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiClipboardPlusOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-2">
              <p class="text-xs mb-0">
                {{ $t('Lending') }}
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ getUserOverview.lend }}
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              block
              small
              :to="{name:'sharebox-borrow'}"
            >
              {{ $t('Borrow') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mt-4"
    >
      <v-card-text>
        <v-btn
          color="success"
          outlined
          block
          :to="{name:'sharebox-lend'}"
          small
          exact
        >
          {{ $t('YourItem') }}
        </v-btn>
        <v-btn
          color="warning"
          outlined
          block
          class="mt-2"
          :to="{name:'sharebox-can-borrow'}"
          small
          exact
        >
          {{ $t('AvailableItems') }}
        </v-btn>
        <v-btn
          color="accent"
          outlined
          block
          class="mt-2"
          :to="{name:'sharebox'}"
          small
          exact
        >
          {{ $t('BorrowedItems') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <router-view />
  </div>
</template>
<script>
import { mdiClipboardPlusOutline, mdiClipboardMinusOutline } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiClipboardMinusOutline,
      },
    }
  },
  computed: {
    ...mapGetters('sharebox', ['getUserOverview']),
    ...mapGetters('auth', ['currentUser']),
  },
  beforeDestroy() {
    this.setQrData(null)
  },
  created() {
    this.fetchUserOverview()
  },
  methods: {
    ...mapActions('sharebox', ['fetchUserOverview']),
    ...mapMutations('sharebox', ['setQrData']),
  },
}
</script>
<style lang="scss" scoped>
.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.illustrator-img {
  position: absolute;
  right: -3%;
  bottom: 0;
}
</style>
